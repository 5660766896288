import React from "react";
import { graphql, Link } from "gatsby";
import _ from "lodash";
import { DiscussionEmbed } from "disqus-react";
import urljoin from "url-join";
import Layout from "../components/layout";
import SEO from "../components/seo";
import PostCard from "../components/post-card/post-card";
import PostDetails from "../components/post-details/post-details";
import {
  FacebookShareButton,
  WhatsappShareButton,
  TwitterShareButton,
} from "react-share";
import { IoLogoFacebook, IoLogoTwitter, IoLogoWhatsapp } from "react-icons/io";
import {
  BlogPostDetailsWrapper,
  PostPaginationWrapper,
  GridIcon,
  PrevButton,
  NextButton,
  PostName,
  Text,
  ThumbImg,
  RelatedPostWrapper,
  RelatedPostItems,
  RelatedPostTitle,
  RelatedPostSubTitle,
  RelatedPostItem,
  BlogPostFooter,
  PostShare,
  PostTags,
  BlogPostComment,
} from "./templates.style";
import Icon from "../images/grid.svg";
import IconNext from "../images/arrow-next.svg";
import IconPrev from "../images/arrow-prev.svg";

const BlogPostTemplate = ({ pageContext, ...props }: any) => {
  const post = props.data.markdownRemark;
  const { edges } = props.data.allMarkdownRemark;
  const title = post.frontmatter.title;
  const slug = post.fields.slug;
  const siteUrl = props.data.site.siteMetadata.siteUrl;
  const shareUrl = urljoin(siteUrl, "/musica/" + slug);
  const { next, previous } = pageContext;

  const disqusConfig = {
    shortname: process.env.DISQUS_NAME,
    config: { identifier: slug, title },
  };
  return (
    <Layout>
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
      />
      <BlogPostDetailsWrapper>
        <PostDetails
          title={post.frontmatter.title}
          date={post.frontmatter.date}
          preview={
            post.frontmatter.cover == null
              ? null
              : post.frontmatter.cover.childImageSharp.fluid
          }
          description={post.html}
          imagePosition="left"
        />
        <BlogPostFooter
          className={post.frontmatter.cover == null ? "center" : ""}
        >
          {post.frontmatter.tags == null ? null : (
            <PostTags className="post_tags">
              {post.frontmatter.tags.map((tag: string, index: number) => (
                <Link key={index} to={`/tags/${_.kebabCase(tag)}/`}>
                  {`#${tag}`}
                </Link>
              ))}
            </PostTags>
          )}
          <PostShare>
            <span>Comparte este post:</span>
            <FacebookShareButton url={shareUrl} quote={post.excerpt}>
              <IoLogoFacebook />
            </FacebookShareButton>
            <WhatsappShareButton url={shareUrl} title={title}>
              <IoLogoWhatsapp />
            </WhatsappShareButton>
            <TwitterShareButton url={shareUrl} title={title}>
              <IoLogoTwitter />
            </TwitterShareButton>
          </PostShare>
        </BlogPostFooter>
        <BlogPostComment
          className={post.frontmatter.cover == null ? "center" : ""}
        >
          <DiscussionEmbed {...disqusConfig} />
        </BlogPostComment>
      </BlogPostDetailsWrapper>

      <PostPaginationWrapper>
        <PrevButton>
          {next && (
            <Link to={"/musica/" + next.fields.slug}>
              <Text>
                <img src={IconPrev} alt="<" />
                &nbsp; Anterior
              </Text>
              <PostName>
                <ThumbImg>
                  <img
                    src={
                      next.frontmatter.cover == null
                        ? null
                        : next.frontmatter.cover.childImageSharp.fluid.srcWebp
                    }
                    alt={next.frontmatter.title}
                  />
                </ThumbImg>
                <span>{next.frontmatter.title}</span>
              </PostName>
            </Link>
          )}
        </PrevButton>

        <GridIcon>
          <img src={Icon} alt="Grid Icon" />
        </GridIcon>

        <NextButton>
          {previous && (
            <Link to={"/musica/" + previous.fields.slug}>
              <Text>
                Siguiente &nbsp;
                <img src={IconNext} alt=">" />
              </Text>
              <PostName>
                <ThumbImg>
                  <img
                    src={
                      previous.frontmatter.cover == null
                        ? null
                        : previous.frontmatter.cover.childImageSharp.fluid
                            .srcWebp
                    }
                    alt={previous.frontmatter.title}
                  />
                </ThumbImg>
                <span>{previous.frontmatter.title}</span>
              </PostName>
            </Link>
          )}
        </NextButton>
      </PostPaginationWrapper>

      {edges.length !== 0 && (
        <RelatedPostWrapper>
          <RelatedPostTitle>más posts</RelatedPostTitle>
          <RelatedPostSubTitle>que te pueden interesar</RelatedPostSubTitle>
          <RelatedPostItems>
            {edges.map(({ node }: any) => (
              <RelatedPostItem key={node.fields.slug}>
                <PostCard
                  title={node.frontmatter.title || node.fields.slug}
                  url={"/musica/" + node.fields.slug}
                  image={
                    node.frontmatter.cover == null
                      ? null
                      : node.frontmatter.cover.childImageSharp.fluid
                  }
                  tags={node.frontmatter.tags}
                />
              </RelatedPostItem>
            ))}
          </RelatedPostItems>
        </RelatedPostWrapper>
      )}
    </Layout>
  );
};

export default BlogPostTemplate;

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!, $tag: [String!]) {
    site {
      siteMetadata {
        siteUrl
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      fields {
        slug
      }
      frontmatter {
        title
        date(formatString: "DD MMM YYYY", locale: "es")
        description
        tags
        cover {
          publicURL
          childImageSharp {
            fluid(maxWidth: 1170) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    }
    allMarkdownRemark(
      limit: 3
      sort: { fields: [frontmatter___date], order: DESC }
      filter: {
        frontmatter: { tags: { in: $tag } }
        fields: { slug: { ne: $slug } }
      }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            tags
            cover {
              publicURL
              childImageSharp {
                fluid(maxWidth: 480, maxHeight: 285) {
                  ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }
              }
            }
          }
        }
      }
    }
  }
`;
